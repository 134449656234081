import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  // {
  //   path: '/',
  //   component: () => import('@/views/Home.vue')
  // },
  // {
  //   path: '/account',
  //   component: () => import('@/views/Account.vue')
  // },
  // {
  //   path: '/search',
  //   component: () => import('@/views/Search.vue')
  // },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/register',
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    path: '/reset',
    component: () => import('@/views/ResetLoginPage.vue')
  },
  {
    path: '/app',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/app/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'account',
        component: () => import('@/views/AccountPage.vue')
      },
      {
        path: 'search',
        component: () => import('@/views/SearchPage.vue')
      },
      {
        path: 'notifications',
        component: () => import('@/views/NotificationsPage.vue')
      },
      {
        path: 'messages',
        component: () => import('@/views/MessageListPage.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/ViewProfile.vue')
      },
      {
        path: 'saved',
        component: () => import('@/views/MySavesPage.vue'),
      },
      {
        path: 'saved/products',
        component: () => import('@/views/SavedProductsPage.vue')
      },
      {
        path: 'saved/posts',
        component: () => import('@/views/SavedPostsPage.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
